
import { UserTypes, SystemUser } from "@/store/modules/user/user.types";
import { Pagination } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { namespace } from "vuex-class";
import { commaDate, currency, pad, slash, dash, uuid } from "@/filters/utils";

const userX = namespace("User");

@Component({
  components: {
    IDatePicker: () => import("@/components/utils/IDatePicker.vue"),
    AddUser: () => import("@/components/user/AddUser.vue"),
    EditUser: () => import("@/components/user/EditUser.vue"),
    DeleteUser: () => import("@/components/user/DeleteUser.vue"),
  },
  filters: {
    currency,
    slash,
    commaDate,
    pad,
    dash,
    uuid,
  },
})
export default class UsersView extends Vue {
  @userX.Mutation(UserTypes.SET_ADD_USER_DIALOG)
  public setAddUser!: (addUser: boolean) => void;

  @userX.Mutation(UserTypes.SET_UPDATE_USER_DIALOG)
  public setUpdateUser!: (updateUser: boolean) => void;

  @userX.Mutation(UserTypes.SET_DELETE_USER_DIALOG)
  public setDeleteUser!: (deleteUser: boolean) => void;

  @userX.Mutation(UserTypes.SET_TICKET_USER_DATA)
  public setSystemUserData!: (ticketUser: SystemUser) => void;

  @userX.Action(UserTypes.LOAD_USERS)
  public getTicketUsers!: (pagination: Pagination) => void;

  @userX.State(UserTypes.USERS)
  public ticketUsers!: SystemUser[];

  @userX.State(UserTypes.LOADING_USERS_STATE)
  public loadingUsers!: boolean;

  @userX.State("addUserRef")
  public addUserRef!: number;

  openAddUserDialog(): void {
    this.setAddUser(true);
  }

  public search = "";

  public headers: DataTableHeader[] = [
    {
      text: "#ID",
      sortable: false,
      value: "id",
      cellClass: "primary--text text--darken-2 font-weight-medium",
    },
    { text: "NAME", value: "name", cellClass: "grey--text text--darken-2" },
    {
      text: "EMAIL",
      value: "email",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "PHONE",
      value: "phone",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "STATUS",
      value: "active",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "CREATED",
      value: "createdAt",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "ACTIONS",
      value: "actions",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
  ];

  deleteUser(item: SystemUser): void {
    this.setDeleteUser(true);
    this.setSystemUserData(item);
  }

  editUser(item: SystemUser): void {
    this.setUpdateUser(true);
    this.setSystemUserData(item);
  }

  mounted(): void {
    this.getTicketUsers({ page: 1, limit: 10 });
  }
}
